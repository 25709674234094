import { call, put, takeLatest, SagaReturnType, take, cancelled, race, select } from 'redux-saga/effects';

import DashboardService from '_api/dashboard-service';
import {
  iframesRefreshTrigger,
  closeFileUploadingStatusConnection,
  getFileUploadingStatus,
  getFileUploadingStatusFailure,
  getFileUploadingStatusRequest,
  getFileUploadingStatusSuccess,
  uploadFile,
  uploadFileFailure,
  uploadFileRequest,
  uploadFileSuccess
} from './slices';
import { updateAccessToken } from 'store/auth/slices';
import { openSnackbar } from 'store/reducers/snackbar';
import { userSelector } from 'store/auth/selectors';
import { taskIdFromAxiosSelector, taskIdSavedUserIdSelector } from './selectors';

import { UploadFileResponseStatus } from 'types/dashboard';
import { AppRootState } from 'types';
import { TASK_INIT_ERROR_MESSAGE } from 'config';
import { getUploadingSseStatus } from 'utils/common';

export function* uploadFileHandler({ payload }) {
  yield put(uploadFileRequest());
  try {
    const response: SagaReturnType<typeof DashboardService.uploadFile> = yield call(DashboardService.uploadFile, payload);
    if (response && response.status === 200) {
      const user = yield select(userSelector);
      yield call([localStorage, localStorage.setItem], 'taskIdFromAxios', response.data.taskId);
      yield call([localStorage, localStorage.setItem], 'taskIdSavedUserId', user?.sub);
      return yield put(
        uploadFileSuccess({
          ...response.data,
          taskIdSavedUserId: user?.sub
        })
      );
    } else {
      yield put(uploadFileFailure('Upload file error'));
    }
  } catch (err) {
    yield put(uploadFileFailure(err?.response?.data?.localizedMessage));
    if (err?.response) {
      yield put(
        openSnackbar({
          open: true,
          message: err?.response?.data?.localizedMessage || 'Upload file error',
          alert: {
            color: 'error'
          },
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transition: 'SlideLeft'
        })
      );
    }
  }
}

export function* getFileUploadingStatusHandler() {
  yield put(getFileUploadingStatusRequest());
  const channel = yield call(DashboardService.createEventSourceConnection);
  try {
    while (true) {
      const { cancelAction, responseData } = yield race({
        cancelAction: take(closeFileUploadingStatusConnection),
        responseData: take(channel)
      });

      if (cancelAction) {
        channel.close();
        yield put(getFileUploadingStatusFailure('Get file uploading status failure - closed by cancelAction'));
      } else {
        if (responseData && responseData.hasOwnProperty('data')) {
          const parsedData = JSON.parse(responseData.data);

          const taskCurrentStatus = yield select((state: AppRootState) => state.dashboard.sseTask?.status);
          const taskIdFromAxios = yield select(taskIdFromAxiosSelector);
          const user = yield select(userSelector);
          const taskIdSavedUserId = yield select(taskIdSavedUserIdSelector);
          const isCurrentUserSavedTaskId = user?.sub === taskIdSavedUserId;
          if (
            (parsedData.status === UploadFileResponseStatus.LoadingError ||
              parsedData.status === UploadFileResponseStatus.ProcessingError ||
              parsedData.status === UploadFileResponseStatus.ProcessingFailed ||
              parsedData.status === UploadFileResponseStatus.UnknownError ||
              parsedData.status === UploadFileResponseStatus.ProcessingSuccess) &&
            taskIdFromAxios &&
            isCurrentUserSavedTaskId
          ) {
            const resultStatus = getUploadingSseStatus(parsedData.status);
            yield put(
              openSnackbar({
                open: true,
                message: resultStatus.text,
                alert: {
                  color: resultStatus.type
                },
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                transition: 'SlideLeft'
              })
            );
          }

          if (
            taskCurrentStatus === UploadFileResponseStatus.ProcessingRunning &&
            parsedData.status === UploadFileResponseStatus.ProcessingSuccess
          ) {
            yield put(iframesRefreshTrigger());
          }

          if (
            parsedData.status === UploadFileResponseStatus.ProcessingSuccess ||
            parsedData.status === UploadFileResponseStatus.LoadingError ||
            parsedData.status === UploadFileResponseStatus.ProcessingError ||
            parsedData.status === UploadFileResponseStatus.ProcessingFailed ||
            parsedData.status === UploadFileResponseStatus.UnknownError ||
            parsedData.errorMessage === TASK_INIT_ERROR_MESSAGE
          ) {
            yield call([localStorage, localStorage.removeItem], 'taskIdFromAxios');
            yield call([localStorage, localStorage.removeItem], 'taskIdSavedUserId');
          }
          yield put(getFileUploadingStatusSuccess(parsedData));
        } else if (responseData && responseData.status === 401) {
          yield put(getFileUploadingStatusFailure('Get file uploading status failure - 401 error'));
          channel.close();
          yield put(updateAccessToken(true));
        } else if (responseData && responseData.hasOwnProperty('closed')) {
          yield put(getFileUploadingStatusFailure('Get file uploading status failure - closed by server'));
          yield put(getFileUploadingStatus());
        }
      }
    }
  } catch (error) {
    yield put(getFileUploadingStatusFailure(error || 'Get file uploading status failure - catched error'));
  } finally {
    if (yield cancelled()) {
      channel.close();
      yield put(getFileUploadingStatusFailure('Get file uploading status failure - cancelled in finally'));
    }
    yield put(getFileUploadingStatusFailure('Get file uploading status failure - finally'));
  }
}

export default function* () {
  yield takeLatest(uploadFile, uploadFileHandler);
  yield takeLatest(getFileUploadingStatus, getFileUploadingStatusHandler);
}
