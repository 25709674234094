import { eventChannel } from 'redux-saga';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { AxiosResponse } from 'axios';

import { API_UPLOAD_FILES_BASE_URL } from 'config';
import axios from 'utils/axios';
import { dispatch } from 'store';
import { setUploadFileProgressFromAxios } from 'store/dashboard/slices';

const controller = new AbortController();
export let uploadController;

export default class DashboardService {
  static async uploadFile(file: File): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    uploadController = new AbortController();
    uploadController.signal.onabort = () => {
      dispatch(
        setUploadFileProgressFromAxios({
          loaded: 0,
          total: 0
        })
      );
      uploadController = undefined;
    };

    return axios.post('/airflow/files/mdlp_disposal', formData, {
      baseURL: API_UPLOAD_FILES_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      signal: uploadController.signal,
      onUploadProgress: function (progressEvent) {
        const { loaded = 0, total = 0 } = progressEvent;
        dispatch(
          setUploadFileProgressFromAxios({
            loaded,
            total
          })
        );
      }
    });
  }

  static createEventSourceConnection() {
    return eventChannel((emitter) => {
      fetchEventSource(`${API_UPLOAD_FILES_BASE_URL}/airflow/files/mdlp_disposal/status`, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/event-stream',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        },
        signal: controller.signal,
        async onopen(response) {
          emitter(response);
        },
        onmessage(message) {
          emitter(message);
        },
        onclose() {
          emitter({
            closed: true
          });
        }
      });

      return () => {
        controller.abort();
      };
    });
  }
}
