const URLS = {
  DASHBOARD: {
    BASE: '/dashboard'
  },
  FAQ: '/faq',
  LOGIN: '/login',
  CODE: '/oauth/code',
  USERS: {
    BASE: '/users'
  }
};

export default URLS;
