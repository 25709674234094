// material-ui
import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ==============================|| DRAWER HEADER - STYLED ||============================== //

interface Props {
  theme: Theme;
  open: boolean;
}

const shouldNotForwardPropsWithKeys =
  <CustomProps>(props: Array<keyof CustomProps>) =>
  (propName: PropertyKey): boolean =>
    !props.map((p) => p.toString()).includes(propName.toString());

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: shouldNotForwardPropsWithKeys<Props>(['open']) })<Props>(
  ({ theme, open }: Props) => ({
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: open ? 'flex-start' : 'center',
    paddingLeft: theme.spacing(open ? 1.5 : 0),
    paddingTop: '9px'
  })
);

export default DrawerHeaderStyled;
