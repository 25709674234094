import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import config from 'config';
import { isAuthorizedSelector } from 'store/auth/selectors';

import { GuardProps } from 'types/auth';

const GuestGuard = ({ children }: GuardProps) => {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('refresh_token')) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [isAuthorized, navigate]);

  return children;
};

export default GuestGuard;
