import { Navigate, useRoutes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import CommonLayout from 'layout/CommonLayout';
import GuestGuard from 'utils/route-guard/GuestGuard';

export default function ThemeRoutes() {
  const routes = [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      )
    },
    MainRoutes(),
    LoginRoutes,
    {
      path: '*',
      element: <Navigate to="/" replace />
    }
  ];

  return useRoutes(routes);
}
