import { lazy } from 'react';

import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import URLS from 'constants/urls';

const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthCode = Loadable(lazy(() => import('pages/auth/code')));

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: <AuthLogin />
        },
        {
          path: URLS.LOGIN,
          element: <AuthLogin />
        },
        {
          path: `${URLS.CODE}`,
          element: <AuthCode />
        }
      ]
    }
  ]
};

export default LoginRoutes;
