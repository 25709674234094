import { AxiosResponse } from 'axios';
import { API_KEYCLOAK_BASE_URL, getKeycloakRedirectUri, KEYCLOAK_GRANT_TYPE, KEYCLOAK_TOKEN_URL } from 'config';
import { AuthResponse } from 'types/auth';
import axios, { refreshAxios } from 'utils/axios';

export default class AuthService {
  static async loginGetTokens(code: string): Promise<AxiosResponse<AuthResponse>> {
    return axios.post<AuthResponse>(
      KEYCLOAK_TOKEN_URL,
      new URLSearchParams({
        client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
        grant_type: KEYCLOAK_GRANT_TYPE.AuthorizationCode,
        code,
        redirect_uri: getKeycloakRedirectUri(window.location.origin)
      }),
      {
        baseURL: API_KEYCLOAK_BASE_URL,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  static async updateAccessToken(): Promise<AxiosResponse<AuthResponse>> {
    return refreshAxios.post<AuthResponse>(KEYCLOAK_TOKEN_URL);
  }
}
