import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import config from 'config';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
  isLink?: boolean;
}

const LogoSection = ({ isIcon, sx, to, isLink = true }: Props) => (
  <ButtonBase
    disableRipple
    component={isLink ? Link : 'span'}
    to={isLink ? (!to ? config.defaultPath : to) : ''}
    sx={{
      ...sx,
      cursor: `${isLink ? 'pointer' : 'initial'}`
    }}
  >
    {isIcon ? <LogoIcon /> : <Logo />}
  </ButtonBase>
);

export default LogoSection;
