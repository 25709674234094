import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, JwtDecodedUser } from 'store/auth/types';
import { AuthResponse, LoginRequest } from 'types/auth';

const access_token = localStorage.getItem('access_token');
const access_expires_in = (localStorage.getItem('access_expires_in') && Number(localStorage.getItem('access_expires_in'))) || null;
const refresh_token = localStorage.getItem('refresh_token');
const refresh_expires_in = (localStorage.getItem('refresh_expires_in') && Number(localStorage.getItem('refresh_expires_in'))) || null;

const initialState: AuthState = {
  isAuthorized: false,
  isAuthenticationChecking: false,
  access_token,
  access_expires_in,
  refresh_token,
  refresh_expires_in,
  user: null,
  loading: false,
  accessTokenUpdateWasTriggeredFromSse: false,
  authErrorMessage: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginGetTokens(state: AuthState, action: PayloadAction<{ code: string }>) {},
    loginGetTokensRequest(state: AuthState) {
      state.isAuthorized = false;
      state.access_token = null;
      state.access_expires_in = null;
      state.refresh_token = null;
      state.refresh_expires_in = null;
      state.loading = true;
      state.user = null;
      state.authErrorMessage = null;
    },
    loginGetTokensSuccess(state: AuthState, action: PayloadAction<AuthResponse>) {
      state.isAuthorized = true;
      state.access_token = action.payload.access_token;
      state.access_expires_in = action.payload.expires_in;
      state.refresh_token = action.payload.refresh_token;
      state.refresh_expires_in = action.payload.refresh_expires_in;
      state.loading = false;
      state.authErrorMessage = null;
    },
    loginGetTokensFailure(state: AuthState, action: PayloadAction<string>) {
      state.isAuthorized = false;
      state.access_token = null;
      state.access_expires_in = null;
      state.refresh_token = null;
      state.refresh_expires_in = null;
      state.loading = false;
      state.authErrorMessage = action.payload;
    },
    resetErrorMessage(state: AuthState) {
      state.authErrorMessage = null;
    },

    setDecodedUser(state: AuthState, action: PayloadAction<JwtDecodedUser>) {
      state.user = action.payload;
    },

    checkAuthentication(state: AuthState) {},
    checkAuthenticationRequest(state: AuthState) {
      state.user = null;
      state.isAuthenticationChecking = true;
      state.loading = true;
    },
    checkAuthenticationSuccess(state: AuthState) {
      state.isAuthorized = true;
      state.isAuthenticationChecking = false;
      state.loading = false;
    },
    checkAuthenticationFailure(state: AuthState) {
      state.isAuthorized = false;
      state.isAuthenticationChecking = false;
      state.loading = false;
    },

    updateAccessToken(state: AuthState, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.accessTokenUpdateWasTriggeredFromSse = true;
      }
    },
    updateAccessTokenRequest(state: AuthState) {},
    updateAccessTokenSuccess(state: AuthState, action: PayloadAction<AuthResponse>) {
      state.isAuthorized = true;
      state.isAuthenticationChecking = false;
      state.access_token = action.payload.access_token;
      state.access_expires_in = action.payload.expires_in;
      state.refresh_token = action.payload.refresh_token;
      state.refresh_expires_in = action.payload.refresh_expires_in;
      state.loading = false;
      state.authErrorMessage = null;

      if (state.accessTokenUpdateWasTriggeredFromSse) {
        state.accessTokenUpdateWasTriggeredFromSse = false;
      }
    },
    updateAccessTokenFailure(state: AuthState, action: PayloadAction<string>) {
      state.isAuthorized = false;
      state.isAuthenticationChecking = false;
      state.access_token = null;
      state.access_expires_in = null;
      state.refresh_token = null;
      state.refresh_expires_in = null;
      state.loading = false;
      state.authErrorMessage = action.payload;

      if (state.accessTokenUpdateWasTriggeredFromSse) {
        state.accessTokenUpdateWasTriggeredFromSse = false;
      }
    },

    logout(state: AuthState) {},
    logoutRequest(state: AuthState) {},
    logoutSuccess(state: AuthState) {
      state.isAuthorized = false;
      state.access_token = null;
      state.access_expires_in = null;
      state.refresh_token = null;
      state.refresh_expires_in = null;
      state.loading = false;
      state.user = null;
      state.authErrorMessage = null;
    },
    logoutFailure(state: AuthState) {}
  }
});

export const {
  loginGetTokens,
  loginGetTokensRequest,
  loginGetTokensSuccess,
  loginGetTokensFailure,
  resetErrorMessage,
  setDecodedUser,

  checkAuthentication,
  checkAuthenticationRequest,
  checkAuthenticationSuccess,
  checkAuthenticationFailure,

  updateAccessToken,
  updateAccessTokenRequest,
  updateAccessTokenSuccess,
  updateAccessTokenFailure,

  logout,
  logoutRequest,
  logoutSuccess,
  logoutFailure
} = authSlice.actions;

export default authSlice.reducer;
