import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box } from '@mui/material';

// project import
import Drawer from './Drawer';
import useConfig from 'hooks/useConfig';

// types
import { AppRootState } from 'types';
import { openDrawer } from 'store/reducers/menu';
import { drawerWidth } from 'config';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  const { miniDrawer } = useConfig();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const menu = useSelector((state: AppRootState) => state.menu);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: `calc(100% - ${drawerWidth}px)`, flexGrow: 1 }}>
        <Box
          sx={{
            position: 'relative',
            top: 62,
            height: 'calc(100vh - 62px)',
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.grayColors?.gray7,
            p: 3
          }}
        >
          <Outlet context={{ open, handleDrawerToggle }} />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
