import axios from 'axios';
import { API_KEYCLOAK_BASE_URL, KEYCLOAK_GRANT_TYPE, KEYCLOAK_TOKEN_URL, API_BASE_URL } from 'config';
import { AuthResponse } from 'types/auth';
import { dispatch } from 'store';
import { checkAuthentication } from 'store/auth/slices';

const appAxios = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
});

appAxios.interceptors.request.use(
  (config) => {
    if (config.headers && localStorage.getItem('access_token')) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

appAxios.interceptors.response.use(
  (config) => config,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && error?.config && !error?.config?.sent) {
      prevRequest.sent = true;
      try {
        const response = await refreshAxios.post<AuthResponse>(KEYCLOAK_TOKEN_URL);

        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('access_expires_in', String(response.data.expires_in));

        localStorage.setItem('refresh_token', response.data.refresh_token);
        localStorage.setItem('refresh_expires_in', String(response.data.refresh_expires_in));

        const currentTime = new Date().getTime();
        localStorage.setItem('tokens_received_time', String(currentTime));

        return appAxios.request(prevRequest);
      } catch (e) {
        if (e.response.status === 400 || e.response.status === 401) {
          dispatch(checkAuthentication());
        }
      }
    }
    return Promise.reject(error);
  }
);

const refreshAxios = axios.create({
  baseURL: API_KEYCLOAK_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

refreshAxios.interceptors.request.use(
  (config) => {
    config.data = new URLSearchParams({
      client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
      grant_type: KEYCLOAK_GRANT_TYPE.RefreshToken,
      refresh_token: localStorage.getItem('refresh_token') || ''
    });
    return config;
  },
  (error) => Promise.reject(error)
);

export default appAxios;
export { refreshAxios };
