import { Roles } from 'store/auth/types';
import { UploadFileResponseStatus } from 'types/dashboard';

export function isAdmin(roles: Roles) {
  return roles.includes('ADMIN');
}

export function getUploadingSseStatus(status: UploadFileResponseStatus) {
  const result = {
    text: '',
    type: '',
    stage: 0
  };

  switch (status) {
    case UploadFileResponseStatus.Loading:
      result.text = 'Загрузка';
      result.type = 'info';
      result.stage = 30;
      break;
    case UploadFileResponseStatus.LoadingError:
      result.text = 'Ошибка загрузки';
      result.type = 'error';
      result.stage = 0;
      break;
    case UploadFileResponseStatus.LoadingComplete:
      result.text = 'Загрузка завершена';
      result.type = 'info';
      result.stage = 40;
      break;
    case UploadFileResponseStatus.Processing:
      result.text = 'Предобработка';
      result.type = 'info';
      result.stage = 45;
      break;
    case UploadFileResponseStatus.ProcessingQueued:
      result.text = 'Поставлена в очередь на обработку';
      result.type = 'info';
      result.stage = 50;
      break;
    case UploadFileResponseStatus.ProcessingRunning:
      result.text = 'Файл обрабатывается';
      result.type = 'info';
      result.stage = 70;
      break;
    case UploadFileResponseStatus.ProcessingError:
      result.text = 'Ошибка обработки файла';
      result.type = 'error';
      result.stage = 0;
      break;
    case UploadFileResponseStatus.ProcessingFailed:
      result.text = 'Обработка завершена с ошибкой';
      result.type = 'error';
      result.stage = 0;
      break;
    case UploadFileResponseStatus.ProcessingSuccess:
      result.text = 'Загрузка и обработка файла успешно завершены';
      result.type = 'success';
      result.stage = 100;
      break;
    case UploadFileResponseStatus.UnknownError:
      result.text = 'Неизвестная ошибка';
      result.type = 'error';
      result.stage = 0;
      break;
    default:
      result.text = '';
      result.type = '';
      result.stage = 0;
  }

  return result;
}
