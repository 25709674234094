import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActivateUserRequest,
  CreateUserRequest,
  CreateUserResponse,
  DeactivateUserRequest,
  EditUserRequest,
  GetUserByIdResponse,
  GetUsersResponse
} from 'types/users';
import { UsersState } from './types';

const initialState: UsersState = {
  loading: false,
  usersErrorMessage: null,
  addUserDialogOpen: false,
  editingUser: null,
  users: []
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers(state: UsersState) {},
    getUsersRequest(state: UsersState) {
      state.loading = true;
      state.usersErrorMessage = null;
    },
    getUsersSuccess(state: UsersState, action: PayloadAction<GetUsersResponse>) {
      state.loading = false;
      state.users = action.payload;
    },
    getUsersFailure(state: UsersState, action: PayloadAction<string>) {
      state.loading = false;
      state.usersErrorMessage = action.payload;
      state.users = [];
    },

    createUser(state: UsersState, action: PayloadAction<CreateUserRequest>) {},
    createUserRequest(state: UsersState) {
      state.loading = true;
      state.usersErrorMessage = null;
    },
    createUserSuccess(state: UsersState, action: PayloadAction<CreateUserResponse>) {
      state.loading = false;
      state.addUserDialogOpen = false;
    },
    createUserFailure(state: UsersState, action: PayloadAction<string>) {
      state.loading = false;
      state.usersErrorMessage = action.payload;
    },

    getUserById(state: UsersState, action: PayloadAction<string>) {},
    getUserByIdRequest(state: UsersState) {
      state.loading = true;
      state.usersErrorMessage = null;
    },
    getUserByIdSuccess(state: UsersState, action: PayloadAction<GetUserByIdResponse>) {
      state.loading = false;
      state.editingUser = action.payload;
    },
    getUserByIdFailure(state: UsersState, action: PayloadAction<string>) {
      state.loading = false;
      state.usersErrorMessage = action.payload;
      state.editingUser = null;
    },

    editUser(state: UsersState, action: PayloadAction<EditUserRequest>) {},
    editUserRequest(state: UsersState) {
      state.loading = true;
      state.usersErrorMessage = null;
    },
    editUserSuccess(state: UsersState) {
      state.loading = false;
      state.editingUser = null;
    },
    editUserFailure(state: UsersState, action: PayloadAction<string>) {
      state.loading = false;
      state.usersErrorMessage = action.payload;
    },

    resetUsersErrorMessage(state: UsersState) {
      state.usersErrorMessage = null;
    },

    setAddUserDialogOpen(state: UsersState, action: PayloadAction<boolean>) {
      state.addUserDialogOpen = action.payload;
    },

    setEditingUserNull(state: UsersState) {
      state.editingUser = null;
    },

    activateUser(state: UsersState, action: PayloadAction<ActivateUserRequest>) {},
    activateUserRequest(state: UsersState) {
      state.loading = true;
    },
    activateUserSuccess(state: UsersState) {
      state.loading = false;
    },
    activateUserFailure(state: UsersState, action: PayloadAction<string>) {
      state.loading = false;
      state.usersErrorMessage = action.payload;
    },

    deactivateUser(state: UsersState, action: PayloadAction<DeactivateUserRequest>) {},
    deactivateUserRequest(state: UsersState) {
      state.loading = true;
    },
    deactivateUserSuccess(state: UsersState) {
      state.loading = false;
    },
    deactivateUserFailure(state: UsersState, action: PayloadAction<string>) {
      state.loading = false;
      state.usersErrorMessage = action.payload;
    }
  }
});

export const {
  getUsers,
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,

  createUser,
  createUserRequest,
  createUserSuccess,
  createUserFailure,

  getUserById,
  getUserByIdRequest,
  getUserByIdSuccess,
  getUserByIdFailure,

  editUser,
  editUserRequest,
  editUserSuccess,
  editUserFailure,

  resetUsersErrorMessage,
  setAddUserDialogOpen,
  setEditingUserNull,

  activateUser,
  activateUserRequest,
  activateUserSuccess,
  activateUserFailure,

  deactivateUser,
  deactivateUserRequest,
  deactivateUserSuccess,
  deactivateUserFailure
} = usersSlice.actions;

export default usersSlice.reducer;
