import { Grid, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import iclLogo from 'assets/images/icl-logo.svg';

const LogoMain = () => {
  const theme = useTheme();
  return (
    <Grid container justifyContent="start" alignItems="center" sx={{ minWidth: '140px' }}>
      <img src={iclLogo} alt="Icl logo" width="46px" height="46px" />
      <Typography
        variant="h1"
        sx={{
          ml: '15px',
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '24px',
          letterSpacing: '0em',
          color: `${theme.palette.grayColors?.black}`
        }}
      >
        BI Pharma
      </Typography>
    </Grid>
  );
};

export default LogoMain;
