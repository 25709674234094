import { useSelector } from 'react-redux';

import { isAuthenticationCheckingSelector, isAuthorizedSelector } from 'store/auth/selectors';

import { GuardProps } from 'types/auth';
const AuthGuard = ({ children }: GuardProps) => {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const isAuthenticationChecking = useSelector(isAuthenticationCheckingSelector);

  if (isAuthorized && !isAuthenticationChecking) {
    return children;
  }

  return <></>;
};

export default AuthGuard;
