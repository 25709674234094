import { TableRowType } from 'types/table';

export enum FaqBlockType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  INFORMATION = 'INFORMATION',
  LIST = 'LIST',
  TABLE = 'TABLE'
}

export interface FaqBlockBase {
  id: number;
  type: FaqBlockType;
}

export interface FaqImageBlock extends FaqBlockBase {
  type: FaqBlockType.IMAGE;
  src: string;
  alt: string;
  maxWidth?: string;
}

export interface FaqTextBlock extends FaqBlockBase {
  type: FaqBlockType.TEXT;
  isAlone?: boolean;
  spans: {
    id: number;
    title: string;
    isBold?: boolean;
  }[];
}

export interface FaqInformationBlock extends FaqBlockBase {
  type: FaqBlockType.INFORMATION;
  text: string;
}

export interface ListType {
  listId: number;
  items?: ListType[];
  text?: string;
  isCircleType?: boolean;
  simpleList?: boolean;
  oneLineTextList?: boolean;
  red?: boolean;
  isLink?: boolean;
  isMail?: boolean;
  isItalic?: boolean;
}

export interface FaqListBlock extends FaqBlockBase {
  type: FaqBlockType.LIST;
  list: ListType[];
}

export interface FaqTableBlock extends FaqBlockBase {
  type: FaqBlockType.TABLE;
  rows: TableRowType[];
  headers: string[];
  colWidths: string[];
  maxWidth?: string;
}

export type FaqBlock = FaqTextBlock | FaqImageBlock | FaqInformationBlock | FaqListBlock | FaqTableBlock;

export interface Faq {
  id: number;
  title: string;
  blocks: FaqBlock[];
}
