import { createSelector } from '@reduxjs/toolkit';
import { AppRootState } from 'types';

const selectDomain = (state: AppRootState) => state.auth;

export const isAuthorizedSelector = createSelector([selectDomain], (a) => a.isAuthorized);
export const isAuthenticationCheckingSelector = createSelector([selectDomain], (a) => a.isAuthenticationChecking);
export const authLoadingSelector = createSelector([selectDomain], (a) => a.loading);
export const authErrorMessageSelector = createSelector([selectDomain], (a) => a.authErrorMessage);
export const accessTokenSelector = createSelector([selectDomain], (a) => a.access_token);
export const userSelector = createSelector([selectDomain], (a) => a.user);
export const userRolesSelector = createSelector([selectDomain], (a) => a.user?.realm_access?.roles || []);
export const accessTokenUpdateWasTriggeredFromSseSelector = createSelector([selectDomain], (a) => a.accessTokenUpdateWasTriggeredFromSse);
