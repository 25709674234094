import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history';

import auth from 'store/auth/slices';
import users from 'store/users/slices';
import dashboard from 'store/dashboard/slices';
import menu from './menu';
import snackbar from './snackbar';

import { InjectedReducersType } from 'utils/types/injector-typings';

// ==============================|| COMBINE REDUCERS ||============================== //

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    router: connectRouter(history),
    auth,
    users,
    dashboard,
    menu,
    snackbar,
    ...injectedReducers
  });
}
