export enum UploadFileResponseStatus {
  Loading = 'LOADING',
  LoadingError = 'LOADING_ERROR',
  LoadingComplete = 'LOADING_COMPLETE',
  ProcessingQueued = 'PROCESSING_QUEUED',
  Processing = 'PROCESSING',
  ProcessingRunning = 'PROCESSING_RUNNING',
  ProcessingError = 'PROCESSING_ERROR',
  ProcessingFailed = 'PROCESSING_FAILED',
  ProcessingSuccess = 'PROCESSING_SUCCESS',
  UnknownError = 'UNKNOWN_ERROR'
}

enum UploadFileResponseTask {
  MdlpDisposal = 'MDLP_DISPOSAL',
  EventStream = 'EVENT_STREAM'
}

export interface UploadFileResponse {
  createDate: number;
  errorMessage: null | string;
  status: UploadFileResponseStatus;
  task: UploadFileResponseTask;
  taskId: null | string;
  taskIdSavedUserId: null | string;
}

export interface UploadFileAxiosProgress {
  loaded: number;
  total: number;
}

export interface DashboardIframesProps {
  label: string;
  dashboardId: string;
  tabIndex: number;
  iframeKey: number;
}
