import { call } from 'redux-saga/effects';

export function* saveInLocalStorage(access_token: string, expires_in: string, refresh_token: string, refresh_expires_in: string) {
  yield call([localStorage, localStorage.setItem], 'access_token', access_token);
  yield call([localStorage, localStorage.setItem], 'access_expires_in', expires_in);

  yield call([localStorage, localStorage.setItem], 'refresh_token', refresh_token);
  yield call([localStorage, localStorage.setItem], 'refresh_expires_in', refresh_expires_in);

  const currentTime = new Date().getTime();
  yield call([localStorage, localStorage.setItem], 'tokens_received_time', String(currentTime));
}

export function* removeFromLocalStorage() {
  yield call([localStorage, localStorage.removeItem], 'access_token');
  yield call([localStorage, localStorage.removeItem], 'access_expires_in');

  yield call([localStorage, localStorage.removeItem], 'refresh_token');
  yield call([localStorage, localStorage.removeItem], 'refresh_expires_in');

  yield call([localStorage, localStorage.removeItem], 'tokens_received_time');
}
