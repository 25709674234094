import { UserOutlined, BarChartOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { NavItemType } from 'types/menu';

const icons = { UserOutlined, BarChartOutlined, QuestionCircleOutlined };

const pages: NavItemType = {
  id: 'group-pages',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Дашборд',
      type: 'item',
      url: '/dashboard',
      icon: icons.BarChartOutlined
    },
    {
      id: 'users',
      title: 'Пользователи',
      type: 'item',
      url: '/users',
      adminOnly: true,
      icon: icons.UserOutlined
    },
    {
      id: 'faq',
      title: 'Помощь',
      type: 'item',
      url: '/faq',
      icon: icons.QuestionCircleOutlined
    }
  ]
};

export default pages;
