import { lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';

import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import URLS from 'constants/urls';
import MainLayout from 'layout/MainLayout';
import {
  accessTokenSelector,
  isAuthenticationCheckingSelector,
  isAuthorizedSelector,
  userRolesSelector,
  userSelector
} from 'store/auth/selectors';
import { JwtDecodedUser } from 'store/auth/types';
import { setDecodedUser } from 'store/auth/slices';
import { isAdmin } from 'utils/common';

const DashboardPage = Loadable(lazy(() => import('pages/dashboard')));
const UsersPage = Loadable(lazy(() => import('pages/users')));
const FaqPage = Loadable(lazy(() => import('pages/faq')));

const MainRoutes = () => {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const isAuthenticationChecking = useSelector(isAuthenticationCheckingSelector);
  const accessToken = useSelector(accessTokenSelector);
  const user = useSelector(userSelector);

  const dispatch = useDispatch();

  if (isAuthorized && !isAuthenticationChecking) {
    try {
      if (accessToken && !user) {
        const decodedUser = jwtDecode<JwtDecodedUser>(accessToken ?? '');
        decodedUser && dispatch(setDecodedUser(decodedUser));
      }
    } catch (err) {
      console.error(err);
    }
  }

  const roles = useSelector(userRolesSelector);

  return {
    path: '/',
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <MainLayout />
          </AuthGuard>
        ),
        children: isAdmin(roles)
          ? [
              {
                path: URLS.DASHBOARD.BASE,
                children: [
                  {
                    path: '',
                    element: <DashboardPage />
                  }
                ]
              },
              {
                path: URLS.FAQ,
                element: <FaqPage />
              },
              {
                path: URLS.USERS.BASE,
                element: <UsersPage />
              }
            ]
          : [
              {
                path: URLS.DASHBOARD.BASE,
                children: [
                  {
                    path: '',
                    element: <DashboardPage />
                  }
                ]
              },
              {
                path: URLS.FAQ,
                element: <FaqPage />
              }
            ]
      }
    ]
  };
};

export default MainRoutes;
