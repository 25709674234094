import { createSelector } from '@reduxjs/toolkit';
import { TASK_INIT_ERROR_MESSAGE } from 'config';
import { AppRootState } from 'types';

const selectDomain = (state: AppRootState) => state.dashboard;

export const dashboardLoadingSelector = createSelector([selectDomain], (d) => d.loading);
export const taskIdFromAxiosSelector = createSelector([selectDomain], (d) => d.taskIdFromAxios);
export const taskIdSavedUserIdSelector = createSelector([selectDomain], (d) => d.taskIdSavedUserId);
export const dashboardErrorMessageSelector = createSelector([selectDomain], (d) => d.dashboardErrorMessage);
export const uploadFileProgressFromAxiosSelector = createSelector([selectDomain], (d) => d.uploadFileProgressFromAxios);
export const currentActiveTaskSelector = createSelector([selectDomain], (d) =>
  d.sseTask && d.sseTask.errorMessage !== TASK_INIT_ERROR_MESSAGE ? d.sseTask : undefined
);
export const dashboardIframesSelector = createSelector([selectDomain], (d) => d.dashboardIframes);
