import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadFileAxiosProgress, UploadFileResponse, UploadFileResponseStatus } from 'types/dashboard';
import { DashboardState } from './types';
import { TASK_INIT_ERROR_MESSAGE } from 'config';

const taskIdFromAxios = localStorage.getItem('taskIdFromAxios');
const taskIdSavedUserId = localStorage.getItem('taskIdSavedUserId');

const initialState: DashboardState = {
  loading: false,
  uploadFileProgressFromAxios: null,
  sseTask: null,
  taskIdFromAxios,
  taskIdSavedUserId,
  dashboardIframes: [
    {
      label: 'Продажи',
      dashboardId: '1',
      tabIndex: 0,
      iframeKey: 0
    },
    {
      label: 'Прочие',
      dashboardId: '2',
      tabIndex: 1,
      iframeKey: 1
    }
  ],
  dashboardErrorMessage: null
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    uploadFile(state: DashboardState, action: PayloadAction<File>) {},
    uploadFileRequest(state: DashboardState) {
      state.loading = true;
      state.dashboardErrorMessage = null;
      state.sseTask = null;
      state.uploadFileProgressFromAxios = null;
    },
    uploadFileSuccess(state: DashboardState, action: PayloadAction<UploadFileResponse>) {
      state.loading = false;
      state.taskIdFromAxios = action.payload.taskId;
      state.taskIdSavedUserId = action.payload.taskIdSavedUserId;
    },
    uploadFileFailure(state: DashboardState, action: PayloadAction<string>) {
      state.loading = false;
      state.dashboardErrorMessage = action.payload;
    },

    getFileUploadingStatus(state: DashboardState) {},
    getFileUploadingStatusRequest(state: DashboardState) {
      state.loading = true;
      state.dashboardErrorMessage = null;
    },
    getFileUploadingStatusSuccess(state: DashboardState, action: PayloadAction<UploadFileResponse>) {
      state.loading = false;

      if (
        action.payload.status === UploadFileResponseStatus.ProcessingSuccess ||
        action.payload.status === UploadFileResponseStatus.LoadingError ||
        action.payload.status === UploadFileResponseStatus.ProcessingError ||
        action.payload.status === UploadFileResponseStatus.ProcessingFailed ||
        action.payload.status === UploadFileResponseStatus.UnknownError ||
        action.payload.errorMessage === TASK_INIT_ERROR_MESSAGE
      ) {
        state.taskIdFromAxios = null;
        state.taskIdSavedUserId = null;
      }

      state.sseTask = action.payload;
    },
    getFileUploadingStatusFailure(state: DashboardState, action: PayloadAction<string>) {
      state.loading = false;
      state.dashboardErrorMessage = action.payload;
    },

    setUploadFileProgressFromAxios(state: DashboardState, action: PayloadAction<UploadFileAxiosProgress>) {
      if (action.payload?.loaded === action.payload?.total) {
        state.uploadFileProgressFromAxios = null;
      } else {
        state.uploadFileProgressFromAxios = action.payload;
      }
    },

    iframesRefreshTrigger(state: DashboardState) {
      state.dashboardIframes = state.dashboardIframes.map((iframe) => ({
        ...iframe,
        iframeKey: iframe.iframeKey + 2
      }));
    },

    closeFileUploadingStatusConnection(state: DashboardState) {
      state.loading = false;
      state.sseTask = null;
      state.dashboardErrorMessage = null;
    }
  }
});

export const {
  uploadFile,
  uploadFileRequest,
  uploadFileSuccess,
  uploadFileFailure,
  getFileUploadingStatus,
  getFileUploadingStatusRequest,
  getFileUploadingStatusSuccess,
  getFileUploadingStatusFailure,
  setUploadFileProgressFromAxios,
  iframesRefreshTrigger,
  closeFileUploadingStatusConnection
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
