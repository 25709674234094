import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Alert, Fade, Grow, Slide, SlideProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';

// project-import
import IconButton from './IconButton';

// assets
import { CloseOutlined } from '@ant-design/icons';

import { KeyedObject } from 'types/root';
import { AppRootState } from 'types';
import { closeSnackbar } from 'store/reducers/snackbar';

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const snackbar = useSelector((state: AppRootState) => state.snackbar);
  const { anchorOrigin, alert, close, message, open, transition } = snackbar;

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <MuiSnackbar
      TransitionComponent={animation[transition]}
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={handleClose}
      sx={{
        '&.MuiSnackbar-root': {
          zIndex: 1303,
          ...(anchorOrigin.vertical === 'top' && {
            top: '46px'
          })
        }
      }}
    >
      <Alert
        variant={alert.variant}
        color={alert.color}
        icon={false}
        action={
          <>
            {close !== false && (
              <IconButton
                sx={{
                  color: theme.palette.grayColors?.gray1,
                  ...(alert.variant === 'filled' &&
                    alert.color === 'error' && {
                      color: theme.palette.grayColors?.white
                    })
                }}
                size="medium"
                aria-label="close"
                variant="text"
                color={alert.color}
                onClick={handleClose}
              >
                <CloseOutlined />
              </IconButton>
            )}
          </>
        }
        sx={{
          color: theme.palette.grayColors?.gray1,
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '24px',
          letterSpacing: '-0.01em',
          padding: '12px 24px',
          borderRadius: 2,
          ...(alert.variant === 'filled' &&
            alert.color === 'success' && {
              bgcolor: theme.palette.stateColors?.success
            }),
          ...(alert.variant === 'filled' &&
            alert.color === 'error' && {
              bgcolor: theme.palette.stateColors?.error,
              color: theme.palette.grayColors?.white
            }),
          ...(alert.variant === 'filled' &&
            alert.color === 'info' && {
              bgcolor: theme.palette.stateColors?.info
            })
        }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
